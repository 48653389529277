// // script.js

// document.addEventListener('DOMContentLoaded', function () {
//     const steps = document.querySelectorAll('.step');
//     const prevBtn = document.getElementById('prevBtn');
//     const nextBtn = document.getElementById('nextBtn');
//     const submitBtn = document.getElementById('submitBtn');
//     const validationError = document.getElementById('validationError');
//     let currentStep = 0;

//     function showStep(step) {
//         steps.forEach((s, index) => {
//             s.classList.toggle('d-none', index !== step);
//             s.classList.toggle('active', index === step);
//         });

//         prevBtn.classList.toggle('d-none', step === 0 || step === steps.length - 1);
//         nextBtn.classList.toggle('d-none', step === steps.length - 2 || step === steps.length - 1);
//         submitBtn.classList.toggle('d-none', step !== steps.length - 2 || step === steps.length - 1);
//         validationError.classList.add('d-none');

//         // Set active classes for inputs in the current step
//         setActiveClassesForInputs(steps[step]);
//     }

//     function validateStep(step) {
//         const currentStepElement = steps[step];
//         const checkboxes = currentStepElement.querySelectorAll('input[type="checkbox"]');
//         const radios = currentStepElement.querySelectorAll('input[type="radio"]');
//         const emailField = currentStepElement.querySelector('#email');

//         if (checkboxes.length > 0) {
//             return Array.from(checkboxes).some(checkbox => checkbox.checked);
//         }

//         if (radios.length > 0) {
//             return Array.from(radios).some(radio => radio.checked);
//         }

//         if (emailField) {
//             return emailField.value.trim() !== '';
//         }

//         return true;
//     }

//     function setActiveClassesForInputs(stepElement) {
//         const inputs = stepElement.querySelectorAll('.custom-input');

//         inputs.forEach(input => {
//             const label = stepElement.querySelector(`label[for="${input.id}"]`);
//             if (input.checked) {
//                 label.classList.add('active');
//             } else {
//                 label.classList.remove('active');
//             }
//             input.addEventListener('change', function () {
//                 if (input.type === 'radio') {
//                     // Unset active class for all radios with the same name
//                     const radios = stepElement.querySelectorAll(`input[name="${input.name}"]`);
//                     radios.forEach(radio => {
//                         const radioLabel = stepElement.querySelector(`label[for="${radio.id}"]`);
//                         radioLabel.classList.remove('active');
//                     });
//                 }
//                 if (input.checked) {
//                     label.classList.add('active');
//                 } else {
//                     label.classList.remove('active');
//                 }
//             });
//         });
//     }

//     nextBtn.addEventListener('click', function () {
//         if (validateStep(currentStep)) {
//             currentStep++;
//             showStep(currentStep);
//         } else {
//             validationError.classList.remove('d-none');
//         }
//     });

//     prevBtn.addEventListener('click', function () {
//         currentStep--;
//         showStep(currentStep);
//     });

//     showStep(currentStep);
// });


// script.js

document.addEventListener('DOMContentLoaded', function () {
    const steps = document.querySelectorAll('.step');
    const prevBtn = document.getElementById('prevBtn');
    const nextBtn = document.getElementById('nextBtn');
    const submitBtn = document.getElementById('submitBtn');
    const validationError = document.getElementById('validationError');
    let currentStep = 0;

    // Objekt zur Speicherung der gesammelten Formulardaten
    const formData = {
        goals: [],
        timeframe: '',
        budget: '',
        additionalInfo: '',
        email: '',
        name: '',
        phone: ''
    };

    function showStep(step) {
        steps.forEach((s, index) => {
            s.classList.toggle('d-none', index !== step);
            s.classList.toggle('active', index === step);
        });

        prevBtn.classList.toggle('d-none', step === 0 || step === steps.length - 1);
        nextBtn.classList.toggle('d-none', step >= steps.length - 2);
        submitBtn.classList.toggle('d-none', step !== steps.length - 2);
        validationError.classList.add('d-none');

        // Set active classes for inputs in the current step
        setActiveClassesForInputs(steps[step]);
    }

    function validateStep(step) {
        const currentStepElement = steps[step];
        const checkboxes = currentStepElement.querySelectorAll('input[type="checkbox"]');
        const radios = currentStepElement.querySelectorAll('input[type="radio"]');
        const emailField = currentStepElement.querySelector('#email');

        if (checkboxes.length > 0) {
            return Array.from(checkboxes).some(checkbox => checkbox.checked);
        }

        if (radios.length > 0) {
            return Array.from(radios).some(radio => radio.checked);
        }

        if (emailField) {
            // Validate email with a basic regex
            const emailValue = emailField.value.trim();
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(emailValue);
        }

        return true;
    }

    function setActiveClassesForInputs(stepElement) {
        const inputs = stepElement.querySelectorAll('.custom-input');

        inputs.forEach(input => {
            const label = stepElement.querySelector(`label[for="${input.id}"]`);
            if (input.checked) {
                label.classList.add('active');
            } else {
                label.classList.remove('active');
            }
            input.addEventListener('change', function () {
                if (input.type === 'radio') {
                    // Unset active class for all radios with the same name
                    const radios = stepElement.querySelectorAll(`input[name="${input.name}"]`);
                    radios.forEach(radio => {
                        const radioLabel = stepElement.querySelector(`label[for="${radio.id}"]`);
                        radioLabel.classList.remove('active');
                    });
                }
                if (input.checked) {
                    label.classList.add('active');
                } else {
                    label.classList.remove('active');
                }
            });
        });
    }

    nextBtn.addEventListener('click', function () {
        if (validateStep(currentStep)) {
            // Daten aus dem aktuellen Schritt sammeln
            collectStepData(currentStep);
            currentStep++;
            showStep(currentStep);
        } else {
            validationError.classList.remove('d-none');
        }
    });

    prevBtn.addEventListener('click', function () {
        currentStep--;
        showStep(currentStep);
    });

    // Funktion zum Sammeln der Daten aus dem aktuellen Schritt
    function collectStepData(step) {
        const currentStepElement = steps[step];

        if (step === 0) {
            // Ziele (Checkboxen)
            const checkboxes = currentStepElement.querySelectorAll('input[type="checkbox"]:checked');
            formData.goals = Array.from(checkboxes).map(checkbox => checkbox.value);
        } else if (step === 1) {
            // Zeitrahmen (Radio)
            const selectedRadio = currentStepElement.querySelector('input[type="radio"]:checked');
            formData.timeframe = selectedRadio ? selectedRadio.value : '';
        } else if (step === 2) {
            // Budget (Radio)
            const selectedRadio = currentStepElement.querySelector('input[type="radio"]:checked');
            formData.budget = selectedRadio ? selectedRadio.value : '';
        } else if (step === 3) {
            // Zusätzliche Informationen (Textarea)
            const textarea = currentStepElement.querySelector('textarea');
            formData.additionalInfo = textarea.value.trim();
        } else if (step === 4) {
            // Kontaktinformationen (E-Mail, Name, Telefon)
            formData.email = currentStepElement.querySelector('#email').value.trim();
            formData.name = currentStepElement.querySelector('#name').value.trim();
            formData.phone = currentStepElement.querySelector('#tel').value.trim();
        }
    }

    // Validate and submit the form when submit button is clicked
    submitBtn.addEventListener('click', function () {
        if (validateStep(currentStep)) {
            // Daten aus dem letzten Schritt sammeln
            collectStepData(currentStep);

            // Sende alle gesammelten Daten an das PHP-Skript
            fetch('subscribe.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
            .then(response => response.json())
            .then(responseData => {
                if (responseData.message === 'Subscription successful') {
                    // Data Layer Push für Google Tag Manager
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'formSubmitSuccess',
                        'formData': formData // Optional: Überträgt die gesammelten Formulardaten (entferne, wenn nicht benötigt)
                    });
                    // Wenn erfolgreich, zum Dankeschön-Schritt wechseln
                    currentStep++;
                    showStep(currentStep);
                } else {
                    // Fehler behandeln
                    console.error('Error:', responseData.error);
                    validationError.textContent = 'Es gab ein Problem beim Absenden. Bitte versuche es erneut.';
                    validationError.classList.remove('d-none');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                validationError.textContent = 'Es gab ein Problem beim Absenden. Bitte versuche es erneut.';
                validationError.classList.remove('d-none');
            });
        } else {
            validationError.classList.remove('d-none');
        }
    });

    showStep(currentStep);
});